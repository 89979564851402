@import 'ant-custom';
@import "baseStyle";


body{
  background-color: #eeeeee;
  font-size: 12px;
  font-family: PingFang SC, Verdana, Helvetica Neue, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
  overflow-x: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  @extend .box-column-m-m;
  @extend .text-normal;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

footer{
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** user **/

.user{
  width: 100%;
  max-width: 600px;
  margin: auto;
  @extend .relative;
  /* background-color: white; */
}

/*全局通用设置*/
  
a, a:hover{
  color: #999999
}


h3 > a{
  color: black;
}


label{
  color: #999999;
}
/*通用设置*/


.right{
  float: right;
  margin-right: 10px
}

.align-right{
  text-align: right;
}


@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;} 
} 

.fadeout{
  position: fixed;
  top: 45vh;
  width: 100%;
  z-index: 2000;
  animation: fadeOut 6s;
  animation-fill-mode: forwards;
  color: white;
  text-align: center;
  line-height: 2;
}

.fadeout span{
  display: inline-block;
  text-align: left;
  background: #333;
  padding: 10px;
  border-radius: 6px;
  margin: auto;
}


//基于class的css


.shadow{
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
}


.box{
  box-shadow: 0 3px 7px 0 rgba(0,0,0,0.05);
  border-radius: 6px;
  padding: 12px;
  background-color: #ffffff;
}

.box:not(:last-child){
  margin-bottom: 12px;
}

.box hr{
  border-top:none;
  margin-top: 6px;
  border-left:none;
  border-right: none;
  margin-bottom: 12px;
  border-bottom-color: #F2F2F2;
}

hr{
  border-top:none;
  border-left:none;
  border-right: none;
  border-bottom-color: #F2F2F2;
}

//活动标签
.activityTag{
  border: 1px solid #2D49FE;
  color: #2D49FE;
  display: inline-block;
  width: 52px;
  height: 20px;
  margin-right: 4px;
  border-radius: 3px;
  background-color: rgba(255, 241, 241, 1);
  font-family: HarmonyOSSansSC-Regular;
}

.row-coupon{
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.05);
}

.coupon-info{
  background:  #FFF1F1 ;
   border-radius: 5px;
   color:#FF1E1E;
   text-align: center;
  height: 114px;
}

.coupon-white .coupon-body::before, .coupon-white .coupon-body::after{
  display: none;
}

.coupon-white .coupon-body{
  border: 1px solid #e6e6e6
}


.coupon-info h3{
  margin-top: 20px;
  margin-bottom: 2px;
  color:#FF1E1E;
  font-size: 36px;
  font-weight: bold;
  padding: 0px 10px;
}

.coupon-body{

  border-left: none;
}

h3.giftname{
  font-size: 20px;
}

.box-content{
  padding: 10px;
  border-radius: 6px;
  background-color: white;
}

.box-content h3{
  font-size: 14px;
}

.coupons-main, .activities-main{
  padding: 10px;
}

.coupons-summary{
  padding: 18px;
  //background-color: white;

  > .block-stat{
    margin-top: 10px;
  }
}


.coupons-main label, .activities-main label{
  color: #999999;
  margin-right: 5px;
}

.light, .activity-stat label, .ant-btn.tiny.light{
  color: #999999;
}

.coupons-main{
  height: calc(100vh - 140px);
  overflow-y: scroll;
}

.row-coupon:not(:first-child){
  margin-top: 16px;
}

.activities-main, .main-area{
  height: calc(100vh - 60px);
  overflow-y: scroll;
  padding: 0 12px;
}

.text-space{
  margin-top: 6px;
}
//视频点数记录
.points{
  padding: 12px;

  .points-header{
    margin-bottom: 16px;
  }

  .tab{
    margin-top: 8px;
    margin-bottom: 20px;
  }

  hr{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .circle-bg{
    width: 44px;
    height: 44px;
    //background: #F2F4FF;
    background: #F6F6F6;
    //border-radius: 22px;
    //display: inline-block;
    font-size: 14px;
    line-height: 44px;
    //text-align: center;
  }
}

.text-space > .ant-row{
  margin-bottom: 6px;
}

.activities-main h3{
  font-size: 16px;
}

.high-text{
  color: red;
  font-weight:bold
}

.coupons-summary .ant-col{
  text-align: center;
}

.yuan{
  font-size: 0.7em;
}

.activity-status-0{
  color: #BBBBBB /*草稿*/
}

.activity-status-1{
  color: #2D49FE /*待审核*/
}

.activity-status-2{
  color: #FF1E1E  /*已驳回*/
}

.activity-status-3{
  color: green /*进行中*/
}

.activity-status-4, .activity-over{
  color: #BBBBBB /*已经关闭*/
}


.bottom-tool{
  position: fixed;
  bottom: 80px;
  right: 15px;
}

.bottom-tool a{
  padding: 9px 9px;
  //color: #2D49FE;
  color:#FF1E1E;
  line-height: 16px;
  display: inline-block;
  background-color: white;
  border-radius: 30px;
  width: 54px;
  height: 54px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
}

.ant-btn.tiny{
  border-radius: 4px;
  font-size: 1em;
  color: #333333;
  border-color: #E6E6E6;
}

.action-main{
  //margin: 20px 10px;
  //padding: 10px;
  border-radius: 6px;
  background-color: #ffffff;
}

.steps{
  padding: 30px 50px 20px 30px;
  background: white;
}

.input-note{
  padding: 5px 0px;
  font-size: 0.8rem;
  color: #999999;
}

.input-note-ad{
  font-size: 0.8rem;
  color: #5C6595;
  height: 50px;
  padding: 5px;
  border-radius: 6px;
  background-color: #F2F4FF;
}

.input-radio-container{
  margin-left: 16px;
}

.ant-form-item-has-error .input-radio-container input.ant-input{
  border-color: #d9d9d9;
}

.ant-radio-group{
  font-size: 1em;
}

.input-note-ads{
  padding: 5px 50px 5px 5px;
  img{
    width: 100%;
  }
}

.activity-demo-text{
  text-align: right;
}

.free{
  background-color: #FF1E1E;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  padding: 1px;
  margin-left: 5px;
}



.step-index{
  position: relative;
  z-index: 100;
  display: inline-block;
  background-color: #999999;
  width: 24px;
  line-height: 24px;
  border-radius: 15px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
}


.am-steps-item-process .step-index{
  background-color: #2D49FE;
}

.am-steps-item-title{
  font-size: 12px;
  padding: 5px !important;
  font-weight: normal;
}

.steps .current .step-title, .am-steps-item-process .am-steps-item-title{
  color: white;
  background: #2D49FE;
}

.step-arrow::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    border-bottom: 5px solid rgba(255, 37, 37, 1);
    left: 43%;
    top: -10px;
}

.ant-upload.btn-upload{
  width: 50%;
  height: 158px;
  background-color: #F6F6F6;
  border:none;
}

.ant-upload.btn-upload p{
  color: #BBBBBB;
}


.toolbar-footer{
  position: fixed;
  font-size: 10px;
  bottom: 0px;
  height: 59px;
  max-width: 600px;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 0px 5px 0px;
  text-align: center;

  a.active, a:hover{
      //color: #4f7df6;
    color: #FF1E1E;
  }
}


//商家首页
//商家首页
.seller-default{
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
.seller-header{
  //height: 166px;
  width: 100%;
  height: 341px;
  padding: 16px 12px;
  color: #ffffff;
  background: url("../assets/img/seller-info-bg.jpg") no-repeat;
  background-size: 100% 100%;
}
.seller-logo{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-size: 100% 100%;

  margin-right: 10px;

}

.seller-header .container{
  width: 80%;
  margin: auto;
  padding: 18px 0px
}

.seller-info-box{
  border: 1px solid red;
  padding: 17px 12px 34px;

}
.timer-info-box{
  width: 150px;
  height: 22px;
  background-color: rgba(230, 230, 230, 1);
  border-radius: 11px;
  margin: 3px 0 6px;
}
.timerIcon,.icon-xiangdou{
  width: 14px;
  height: 14px;
  margin: 0 4px;
}

.xiangdou-info-box{
  display: inline-block;
  width: 130px;
  height: 22px;
  background: linear-gradient(90deg, rgba(255, 123, 80, 1) 0%, rgba(255, 38, 38, 1) 100%);
  border-radius: 11px;
  padding: 0 6px 0 0;
}

.seller-default .box.seller-stat{
  //margin: -70px 10px 0px 10px;
  //margin: 16px 0 0;
  padding: 18px;
}

.seller-stat.box{
  box-shadow: none;
}



.main-block{
  margin: 0 12px;
}

.my-activities{
  margin-top: 0px;
  margin-bottom: 10px;
}

.login{
  padding: 50px 20px 0px 20px;
  background: #ffffff;
  height: 736px;
}

.login h1{
  text-align: center;
  margin-bottom: 30px;
}

//登录
.loginWrap{
  height: 100vh;
  background: #ffffff;
  padding: 40px 24px 0;

  h1{
    font-family: HarmonyOSSansSC-Bold;
    line-height: 36px;
  }
  h2{
    font-family: HarmonyOSSansSC-Medium;
    margin-bottom: 96px;
  }

  .loginIptWrap{

    .za-cell:after {
      left: 0!important;
    }
    .za-cell__inner {
      padding: 0 12px 0 0 !important;
    }
    .za-cell:after{
      border-top: 0!important;
      border-bottom: 1px solid #dcdcdc!important;
      -webkit-border-bottom: 1px solid #dcdcdc!important;
    }

  }
  .loginBtn{
    height: 48px;
    margin-top: 36px;
    background: linear-gradient(137.05deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%);
    box-shadow: 0px 4px 8px rgba(255, 37, 37, 0.25);
    color:#fff;
    font-family: HarmonyOSSansSC-Medium;
  }
}

.cellVerification{
  .za-cell__inner{
    .za-cell__footer{
      border-left: 1px solid #dedede;
      -webkit-border-left: 1px solid #dedede;
    }
  }
}

.big-number{
  color: #ffffff;
  font-size: 24px;
}

.tab{
  text-align: center;
}





.login-icon{
  padding: 5px;
}

.underline{
  border-bottom: 1px solid #E6E6E6;
  padding: 10px 0px;

  .ant-input{
    border:none;
    padding:10px 0;
    font-size: 14px;

  }
}


.block-submit{
  margin-top: 50px;
  text-align: center;
}
.btn-submit{
  margin-top: 60px;
  text-align: center;

  Button{
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    color:white;
    background: linear-gradient(137.05deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%);
    box-shadow: 0px 4px 8px rgba(255, 37, 37, 0.25);
    border-radius: 6px;
  }
}

.underline .ant-form-item{
  margin-top: 0px;
  margin-bottom: 0px;
}

.underline .ant-input:focus{
  box-shadow:none
}

.ant-row.ant-form-item.inline, .date-picker{
  display: inline-block;
  vertical-align: initial;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.time-picker{
  width: 80px;
  display: inline-block;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.ant-input{
  border-color: #E6E6E6;
  border-radius: 6px;
}


.activity-stat{
  margin-top: 12px;
  background-color: #F5F5FF;
  padding: 15px 12px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  > a{
    margin: -15px -12px;
    padding: 15px 12px;
    display: block;
  }
}

.activity-stat-body{
  margin-top: 20px;
}

.activity-stat h4{
  font-size: 18px;
}

.stat-today{
  color: red;
  margin-bottom: 20px;
}

.warning{
  background-color: #FFF8EE;
  color: #FF8C1E;
  padding: 10px ;
  margin: -12px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-main{
  padding: 20px;
  background-color: #ffffff;
}

.inline.small{
  width: 90px;
}

.inline + .unit{
  margin-left: -1.2rem;
  top: 0.6rem;
  position:absolute;
}

.tip{
  font-size: 12px;
  color: #999999
}

.desc{
  padding: 5px
}

.coupon-status{
  transform: rotate(-45deg);
  font-size: 10px;
  color: #47B725;
  background-color: white;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 8px;
}


.coupon-status-2{
  color: #999999;
}

.coupon-stat{
  margin-top: 6px;
}

.material-thumnail{
  display: grid;
  grid-template-columns: 33% 33% 33%;
  white-space: wrap;
}

.materialItem{
  width: 103px;
  height: 103px;
  margin-bottom: 9px;
}
.material-thumnail > div{

  position: relative;
}

.material-thumnail img, .material-thumnail video{
  width: 100%;
}

.material-thumnail > div > div{
  max-height: 103px;
  overflow-y: hidden;
  border-radius: 5px;
  position: relative;
}

.close{
  position: absolute;
  right: 0px;
  top: 0px;
}
.icon-close{
  width: 20px;
  height: 20px;
  border-radius: 0px 6px 0px 6px;
  opacity: 0.3;
  background:#000000 url("../assets/svg/icon-delete.svg") center center no-repeat;
  margin: 0 auto;
}

.upload-buttons {
  width: 103px;
  height: 103px;
  background:#F6F6F6 url("../assets/icon/icon-upload-video-camera.jpg") 50% 30% no-repeat;
  background-size: 30px 30px;
  margin: 0 0 9px;
  border-radius: 6px;

  label {
    display: block;
    text-align: center;
    cursor: pointer;
  }
}

.progress{
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 100;
  text-align: center;
  padding-top: 100px;
}

.video-duration{
  color: white;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left:0px;
  font-size: 12px;
  text-align: right;
  padding:0px 5px;
}

.action-bottom{
  text-align: center;
  button{
    border-radius: 0;
  }
}

.h2-strip{
  width: 4px;
  height: 12px;
  border-radius:2px;
  //background-color: #2D49FE;
  background-color: #333;
  display: inline-block;
  margin-right: 6px;
}


.poster-container{
  width: 100%;
  height: 100vh;
  //position: absolute;
  //top: 0px;
  //left: 0px;
  z-index: 100;
  color: white;
  padding: 20px 10px 0px 10px;
  text-align: center;
  background-color: #2D2f38;
  overflow-y: scroll;

  .am-segment-item-selected {
    color: #333!important;
  }
  .am-wingblank.am-wingblank-lg{
    margin: 0 !important;
  }
}

//.poster{
//  margin-top: 20px;
//}

//.poster >img{
//  max-width: 100%;
//  max-height: 60vh;
//}

.poster-buttons{
  line-height: 50px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 -1px 0 0 #F2F2F2;
}

.poster-buttons .ant-btn-link{
  color: #333333;
}

.poster-desc{
  margin: 24px 0px 8px;
  color: #FFB931;
}

.ant-form-item-label{
  font-weight: bold;
}

.radio-coupon{
  text-align: center;
}

.radio-coupon > label{
  margin: 0px;
  margin-top: 30px;
}

.ant-form-item-label > label{
  font-size: 1.1em;
}

.ant-form-item.medium, .date-picker{
  width: 150px;
  display: inline-block;
}

.ant-form-item.small{
  width: 80px;
  display: inline-block;
}

.view{
  font-size: 14px;
}

.license-inline{
  height: 30px;
}

.tiny{
  /* font-size: 12px !important; */
  padding: 0px 3px;
}

.activity-tools{
  overflow: hidden;
}

.activity-tools a{
  color: #333333;
}

.space-container{
  padding: 10px;
}

.coupon-record:not(:last-child){
  margin-top: 10px;
}

.coupon-record h3{
  font-size: 14px;
}

.coupon-type {
  margin-right: 2px;
}

input[type='date'].ant-input{
  width: 140px !important;
}

input[type='time'].ant-input{
  width: 90px !important;
}

input.small{
  width: 80px;
}


.am-steps-item-tail::after{
  background: none !important;
  border-bottom: 2px dashed #cccccc;
}

.datepicker-modal{
  position: fixed !important;
}

.borderless{
  border:none;
}

.center{
  text-align: center;
}

.ant-list-item{
  padding:10px;
  font-size: 12px;
}

.ant-list-item >label{
  font-size: 14px;
  color: #333333;
}

.my-header{
  background-image: linear-gradient(0deg, #EEEEEE 0%, #E1E8FF 98%);
  padding: 20px 12px;
  margin: -12px -10px 0px -10px;
  height: 200px;
}

button.full-width{
  width: 80%;
  height: 48px;
  padding: 10px;
  margin: auto;
  border:none;
  border-radius: 6px;
  //color: #999999;
  @extend .text-def3;
}

button.gradient{
  width: 100%;
  height: 48px;
  padding: 10px;
  margin: auto;
  border:none;
  border-radius: 6px;
  //color: #999999;
  @extend .text-def3;
  background: linear-gradient(137.05deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%);

}


.button-blue{
  //background: rgba(45,73,254,0.70);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgba(255, 30, 30, 1);
  border-radius: 2px;
}


.red{
  color: red;
}

button.blue{
  border: 1px solid #2D49FE;
  border-radius: 4px;
  letter-spacing: 0px;
  color: #2D49FE 
}

.top-white{
  background: white;
  padding: 10px;
}

.stat-small h3{
  font-size: 1.1rem;
}

.stat-bg{
  background: #F5F5FF;
  padding: 10px;
  margin: 5px -12px -12px -12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  
}

.seller-logo-plain{
  width: 48px;
  max-height: 48px;
  border-radius: 6px;
  margin: 5px auto;
}

.seller-logo-plain img{
  width: 100%;
}


.ant-modal.transparent .ant-modal-content{
  background-color: rgba(0,0,0,0);
  box-shadow: none;
}


.center{
  text-align: center;
}

.block-link{
  display: block;
  color: initial
}

.block-link:hover{
  color: initial
}

.coupon-tab{
  padding: 10px 0px;
}

.my-points-header{
  height: 82px;
  color: #FFE4DE;
  //color: white;
  background:url("../assets/img/xiangdou-info-bg.png") no-repeat;
  background-size: 100% auto;
  //padding: 14px 28px;
  //box-shadow: #2D49FE30 0 4px 8px;
  //padding: 0 4px;
  box-shadow: 0px 3px 7px rgba(255, 37, 37, 0.25);
  border-radius: 6px;
  font-size: 12px;

  h2{
    font-size: 24px;
    color: white;
    margin: 0px !important
  }

  & label{
    color: #C8DCFF
  }
}
.xiangdouNum{
  font-size: 24px;
  line-height: 24px;
}
.xiangdouItemDetail{
  height:68px;
  padding:12px 0;
  border-bottom:1px solid #F2F2F2;
}

.xiangdouItemBox{
  div:last-child{
    .xiangdouItemDetail{
      border:none;
    }
  }
}
.xiangDouItemIcon{
  margin-top: -2px;
}

.site-subsidary{
  .my-header{
      height: auto;
  }

}

.my-header{

  h2{
    font-size: 18px;
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow:hidden
  }

  a{
    color: #666666;
  }
  
}




.fillin-points{
  width: 62px;
  height: 30px;
  background-image: linear-gradient(0deg, #FFE893 0%, #FFF3D4 99%);
  //box-shadow: 0 2px 4px 0 #1333DC;
  border-radius: 4px;
  border-radius: 4px;
  color: #BE8D00;
  font-size: bold;
  margin-top: 5px;
  letter-spacing: 0px;
  border:none;
}

.footer-menu{
  display: flex;
}

.footer-menu > div{
  width: 20%;
}

.ant-radio-button-wrapper-checked{
  background: #2D49FE !important;
  color: white !important;
}

.tab-white .ant-radio-button-wrapper-checked{
  background: white !important;
  color: black !important;
}

.tab-white .ant-radio-button-wrapper{
  color: white;
  background: transparent;
  border-color: white !important;
}

.tab-white .ant-radio-button-wrapper{
  border-color: white !important;
  margin-right: 0px;
}

.tab-white .ant-radio-button-wrapper:not(:first-child)::before{
  background: white;
}


.ant-radio-button-wrapper{
  border-color: #2D49FE !important;
  color: #2D49FE; 
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
}

.coupon-tab .ant-radio-button-wrapper{
  height: 70px;
  line-height: 1.5;
  padding-top: 10px;
  padding-left:15px;
  padding-right: 15px;
  border-radius: 6px;
  margin: 3px;
  border-left-width: 1px;
  border-color: white !important;
}

.coupon-tab .ant-radio-button-wrapper:not(:first-child)::before{
  display: none;
}

.strike{
  text-decoration: line-through;
}


span.icon{
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
}



.linespace > div{
  margin-top: 8px;
  label{
    margin-top: 4px;
    display: inline-block;
  }
}

@keyframes slideup {
  0%{bottom: -200px}
  100%{bottom:0px}
}

.popup-bottom-mask{
  position: fixed;
  left:0px;
  top:0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;

}

.popup-bottom{
  position: absolute;
  bottom:0px;
  left:0px;
  width: 100%;
  text-align: left;
  background-color: #ffffff;
  animation: 0.3s slideup;

  .link-block > a{
    display: block;
    height: 48px;
    color: black;
    padding-left: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img{
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .link-block > a:not(:last-child){
    border-bottom: 1px solid #eeeeee;
  }

  .popup-cancel{
    height: 48px;
    line-height: 48px;
    text-align: center;
    background-color: white;
    border-top: 4px solid #f0f0f0;
  }
}

.btn-borderonly{
  background: none;
  border: 1px solid white;
  color: white;
  border-radius: 6px;
}

.blue, a.blue:hover{
  color:#2D49FE
}

.bold{
  font-weight: bold;
}

.adjust-points{
  label{
    line-height: 2;
  }
}

.coupon-body::before{
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  background: #eee;
  left: -6px;
  top: -6px;
  border-radius: 20px;
}

.coupon-body::after{
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  background: #eee;
  left: -6px;
  bottom: -6px;
  border-radius: 20px;
}

.sorticon{
  width: 8px;
}

.video-desc-strcount{
  position: absolute;
  right: 10px;
  bottom: 30px;
}

.overlay-full{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
  padding: 10px; 
}

.bkm-btn-fill{
  background: #2D49FE;
  color: white;
  border:none;
  border-radius: 6px;
  padding: 5px 8px;
}

.ant-radio-button-wrapper{
  border-color:  rgba(255, 30, 30, 1)  !important;
  color:rgba(255, 30, 30, 1);
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
}
.ant-radio-button-wrapper:before{
  background-color:  rgba(255, 30, 30, 1)  !important;

}

.ant-radio-button-wrapper-checked{
  background: rgba(255, 30, 30, 1) !important;
  color: white !important;
}