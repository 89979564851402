.seller-root{


  h1{
    font-size: 24px;
  }


  h2{
    font-size: 16px;
  }

  h3{
    font-size: 16px;
    margin: 0px;
  }

  h3 > .icon{
    height: 28px;
  }


  .seller-header h2{
    //color: #ffffff;
    margin:0px;
    font-size: 18px;
    max-width: 228px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .small-padding{
    padding: 6px;
  }

  .activity-input-points{
    height: 36px;
  }

  .coupon-desc{
    padding: 12px;
    background: #F6F6F6;
    color: #999999;
    border-radius: 6px;
    margin: 20px 0px 5px 0px;

    h3{
      font-size: 14px;
      margin-bottom: 5px;
    }
  } 

  .smscode-button:disabled{
    color: #cccccc !important;
  }

  .smscode-button{
    color: #2D49FE !important
  }

  .icon-home{
    background-image: url('../../assets/icon/home.png');
  }

  
  .active .icon-home{
    background-image: url('../../assets/icon/home_active.png');
  }
  
  .icon-activity{
    background-image: url('../../assets/icon/activity.png');
  }
  
  .active .icon-activity{
    background-image: url('../../assets/icon/activity_active.png');
  }
  
  .icon-coupon{
    background-image: url('../../assets/icon/coupon.png');
  }
  
  .active .icon-coupon{
    background-image: url('../../assets/icon/coupon_active.png');
  }


  .use-coupon{
    background-image: url('../../assets/icon/scan2.png');
  }

  .active .use-coupon{
    background-image: url('../../assets/icon/scan1.png');
  }



  .icon-my{
    background-image: url('../../assets/icon/my.png');
  }
  
  .active .icon-my{
    background-image: url('../../assets/icon/my_active.png');
  }

  .icon-mobile{
    background-image: url('../../assets/svg/mobile.svg');
  }

  .icon-password{
    background-image: url('../../assets/svg/password.svg');
  }

  .icon-smscode{
    background-image: url('../../assets/icon/smscode.png');
  }
    


  input.topic-filter{
    border: none;
    background: #F2F2F2;
    padding: 5px 10px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }

  .topic-list{
    margin: 10px 0px;
    overflow: scroll;
    height: calc(100% - 50px);
  }

  .topic-list > div{
    padding: 6px;
  }

  .topic-item{
    display: inline-block;
    border: 1px solid #2D49FE;
    border-radius: 5px; 
    margin: 0px 5px 5px 0px; 
    position: relative; 
    padding-left: 5px; 
    color: #2D49FE;
  }

  .view .topic-item{
    padding-right: 5px;
  }

  .topic-item .topic-remove{
    border-left: 1px solid #2D49FE;
    margin-left: 5px;
    padding: 5px;
    display: inline-block;
  }
}


.v-middle{
  padding: 5px 0px;
  display: inline-block;
}


.bkm-tab-button{
  background-color: #F2F2F2;
  padding: 2px;
  border-radius: 6px;

  .selected{
    color: black;
    background-color: white;
    border-radius: 6px;
    font-weight: bolder;
  }

  > span{
    display: inline-block;
    padding: 8px 20px;
    color:  #999999;
    text-align: center;
  }

}

.coupon-expired{
   *{
    color: #999999 !important;
  }

}

.ant-input.smscode-captcha{
  background: #F6F6F6;
  padding:10px;
  border:none;
}

.padding-space{
  padding: 10px
}

.createActWrap{
  margin: 0px 12px;
  padding-top: 100px;
  position: relative;
}

.createActStepHeader{
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: white;
  z-index: 10;
}
.createActContent{
  height: calc( 100vh - 180px);
  background-color: white;
  margin-top: 15px !important;
  padding: 5px !important;
  overflow: auto;
  .ant-card-body{
    padding: 10px 5px 10px 5px !important;
  }
}

.createCouponLeft{
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 150px;
  height: 50px;
  background-color: white;
  z-index: 10;
  padding: 3px 0px 3px 20px;
  img{
    width: 35px;
    height: 28px;
  }
  span{
    padding-bottom: -10px;
  }
}
.createCouponBottom{
  border-top: 1px solid #e1e1e1;
}

.createCardWrap{
  padding: 0px 10px 100px 10px;
  background-color: #F2F2F2 !important;
  overflow: hidden;
}

.createCouponContent{
  height: calc( 100vh - 100px);
  background-color: white;
  margin-top: 15px !important;
  padding: 5px !important;
  overflow: auto;
  .ant-card-body{
    padding: 10px 5px 10px 5px !important;
  }
}


