.consumer-join{
    background: #171590 url("../../assets/img/consumer_join_bg.png") no-repeat;
    background-size: 100% auto;
    color: white;
    text-align: center;
    min-height: 100vh;

  h1, h2{
    color: white;
  }

  h1{
    padding: 46px 10px 15px 10px;
    line-height:1.2;
    margin:0px;
    font-size: 52px;
    height: 180px;
  }


  h3{
    color: white;
    margin-bottom: 5px;
  }

  .phone{
    font-size: 18px;
    letter-spacing: 4px;
    padding: 10px;
    width: 200px;
    color: #333333;
  }

  .activity-duration{
    width: 247px;
    padding: 4px 2px;
    margin: auto;
    background: url('../../assets/img/bg_activity_duration.jpg') no-repeat;
    background-size: auto 100%;
  }

  .activity-desc{
    border-radius: 6px;
    border: 1px dotted #5876F9;
    margin: 20px auto;
    padding: 10px;
    width: 345px;
  }

  button.join-submit{
    width: 225px;
    height: 50px;
    border:none;
    border-radius: 10px;
    box-shadow:0px 5px 9px #0A078690;
    background:  rgba(0,0,0,0) url('../../assets/img/consumer_join_btn.jpg') center center no-repeat;
    background-size: cover;
    color: white !important;
  }

  .btn-know{
    width: 225px;
    height: 49px;
    background: url('../../assets/img/btn-know.png') center center no-repeat;
    background-size:100% 100%;
    border-radius: 10px;
  }

  .consumer-join-coupon{
    width: 345px;
    height: 106px;
    padding: 20px 0px;
    margin: 100px auto 0px auto;
    background: url('../../assets/img/consumer_join_coupon_bg.jpg') center center no-repeat;
    background-size:auto 100%;
    font-size: 12px;
    text-align: center;
  }

  .consumer-join-coupon h2{
    font-size: 30px;
    margin: 0px;
  }

  .consumer-join-coupon h4{
    color:white;
    font-size: 14px;
  }

  .consumer-join-coupon .ant-col-16{
    text-align: left;
    padding: 5px;
  }

  .consumer-join-coupon .ant-col-8, .consumer-join-coupon h2, .consumer-join-coupon h3{
    color: #FFE940;
  }

  .consumer-join-coupon .join-text{
    padding: 0px 15px;
  }

  .confirm-phone{
    color: black;
    padding: 30px;
  }
}


.ant-btn.btn-cancel{
    background-color: #e6e6e6;
    color: #999999 !important;
}

.success{
    font-family: HarmonyOSSansSC-Regular;
    height: 333px;
    padding-top: 163px;
    background: url('../../assets/img/success_bg.png') no-repeat;
    background-size: 100% auto;
    color: #768293;
    text-align: center;
}

.joinactivty_actionbar{
    width: 100%;
    height: 48px;
    position: fixed;
    bottom: 0px;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
    background: linear-gradient(180deg, rgba(71, 53, 171, 1) 0%, rgba(101, 84, 196, 1) 100%);
  img{
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}
.joinactivty_divider{
  width: 0.5px;
  height: 25px;
  background-color: rgba(255,255,255,0.3);
}