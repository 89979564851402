
.mineBox{
  height:  calc(100vh - 59px);
  //padding: 0 12px;
  overflow-y: scroll;

  .mine-info-box{
    height: 196px;
    padding: 18px 0px;
    background: linear-gradient(180deg, rgba(255, 236, 230, 1) 0%, rgba(238, 238, 238, 1) 100%);
  }

  //list-reset
  .am-list{

    .am-list-body{
      .am-list-item{
        padding-left: 12px;
        .am-list-thumb{
          margin-right: 8px;
        }
        .am-list-line{
          padding-right:12px;
          .am-list-content {
            font-size: 14px;
            color: #333;
          }
          .am-list-extra{
            font-size: 12px;
            color: #333;
          }
          .am-list-arrow{
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }

  .seller-name{
    width: 228px;
  }
  .seller-info-item-box{
    height: 60px;
    margin-bottom: 18px;
    //background-color: #47B725;
    padding: 0 12px;
  }

  .radius-s{
    border-radius: 6px;
  }



}
//动作面板css-reset
.am-action-sheet{
  .am-action-sheet-content{
    .am-action-sheet-body{
      div{
        .am-action-sheet-button-list{
          .am-action-sheet-button-list-item:first-child{
            font-size: 16px!important;
            color:#333!important;
          }
          .am-action-sheet-button-list-item:last-child{
            font-size: 16px!important;
            color:#999!important;
          }
        }
      }
    }
  }
}

.listWrap{
  width: 100%;
  height: 44px;
  border-bottom:1px solid #f2f2f2;
  background-color: white;

  .iconWrap{
    img{
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }

}
.listContentWrap:last-child{
  border: none;
}

.listContentWrap{
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 12px;
}

//tabs-reset
.materialTabsWrap{
  height: calc(100vh - 200px);
  background-color: red;
  .am-tabs{
    display: flex;
    flex-direction: column;
    .am-tabs-content-wrap{
      min-height: 400px;
      flex: 1;
    }
    .am-tabs-tab-bar-wrap{
      .am-tabs-default-bar {
        background: #eee!important;
        border-radius: 6px;
      }

      .am-tabs-default-bar-tab-active{
        background: #fff;
        border: 2px solid #ddd;
        color: #333!important;
        border-radius: 6px;
      }
      .am-tabs-default-bar-underline{
        border: 0;
      }
    }
  }
}

.materialScrollWrap{
  margin-top: 16px;
  height: calc(100vh - 280px);
  overflow: scroll;

  .am-checkbox.am-checkbox-checked .am-checkbox-inner{
    background-color: #ff1e1e!important;
    border-color: #ff1e1e!important;
  }
}

.uploadMetrialWrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //background-color: white;
  //height: 96vh;
  //margin: 2vh;
  padding: 10px;
  border-radius: 5px;
}
.file-picker-btn{

}
.file-picker-wrap{
  width:125px;
  height: 50px;
  background: linear-gradient(137.05deg, #ff7b50 0%, #ff2525 100%);
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  color: white;
}

.file-picker-btn{
  display:flex;
  align-items:center;
  justify-content:center;
  border:2px dashed #ddd;
  width:100px;
  height: 100px;
}

.file-picker-item{
  display:flex;
  align-items:center;
  justify-content:center;
  width:100px;
  height: 100px;
  margin-right: 12px;
  margin-bottom: 10px;
}

.file-picker-closebtn{
  font-size: 20px;
}
.file-picker-item-img{
  width:100%;
  height:100%;
  overflow:hidden;
}
.userInfoWrap{
  overflow-y: scroll;

  .za-cell:after {
    width: 185%;
    border-top:0;
    border-bottom: 1px solid #e6e6e6!important;
    -webkit-border-bottom: 1px solid #e6e6e6!important;
  }
  .za-cell:last-child :after {
    width: 185%;
    border: none!important;
  }

}