ul li{
  list-style: none;
}

//variables

//variables-文本
$text-base-size:16px;
$bold:bold;
$bolder:bolder;
$base-color:#333;

$hidden:hidden;
$wrap:wrap;
$nowrap:nowrap;
$scroll:scroll;
$ellipsis:ellipsis;



//variables-布局
$flex:1;
$layout-flex:flex;
$row:row;
$column:column;
$align-center:center;
$align-start:flex-start;
$align-end:flex-end;
$align-between:space-between;
$align-around:space-around;
$baseline:baseline;
$stretch:stretch;

$align-left:left;
$align-right:right;

$relative:relative;
$absolute:absolute;
$fixed:fixed;

$line-solid:solid;
$line-dotted:dotted;
$line-dashed:dashed;

$auto:auto;
$none:none;
$line-overline:overline;
$line-through:line-through;
$line-underline:underline;
$line-blink:blink;

$inline-block:inline-block;

.box-inline-block{
  display: $inline-block;
}

//文本-溢出省略
.text-ellipsis{
  overflow: $hidden;
  white-space: $nowrap;
  text-overflow:$ellipsis;
}

//文本-下划线
@mixin text-decoration($decoration){
  text-decoration:$decoration;
}
.text-decoration-none{
  @include text-decoration($none);
}
.text-decoration-underline{
  @include text-decoration($line-through);
}



//文本-字号
@mixin font-size($size){
  font-size:$size;
}
@mixin line-height($size){
  line-height: $size;
}
.text-xxs{
  font-size: 22px;
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  @include line-height($text-base-size);//16px
}
.text-xs{
  @include font-size($text-base-size - 4px);//12px
  @include line-height($text-base-size + 2px);//18px
}
.text-s{
  @include font-size($text-base-size - 2px);//14px
  @include line-height($text-base-size + 4px);//20px
}
.text-m{
  @include font-size($text-base-size);//16px
  @include line-height($text-base-size + 8px);//24px
}
.text-l{
  @include font-size($text-base-size + 2px);//18px
  @include line-height($text-base-size + 10px);//26px
}
.text-xl{
  @include font-size($text-base-size + 4px);//20px
  @include line-height($text-base-size + 14px);//30px
}
.text-xxl{
  @include font-size($text-base-size + 4px);//24px
  @include line-height($text-base-size + 8px);//30px
}
.text-xxxl{
  @include font-size($text-base-size + 20px);//36px
  @include line-height($text-base-size + 20px);//36px
}
//文本-字体粗细
.text-bold{
  font-weight: $bold;
}
.text-bolder{
  font-weight: $bolder;
}

//文本-对齐
@mixin text-align($align){
  text-align: $align;
}
.text-align-m{
  @include text-align($align-center);
}
.text-align-left{
  @include text-align($align-left);
}
.text-align-right{
  @include text-align($align-right);
}

//文本-字体颜色
.text-def{
  color:$base-color;
}
.text-def2{
  color:$base-color * 2!important;
}
.text-def3{
  color:$base-color * 3;
}
.text-normal{
  color:$base-color * 5;
}
.text-danger{
  color:#FF1E1E;
}
.text-success{
  color:#47B725;
}
.text-warn{
  color:#FF8C1E;
}
.text-bule{
  color: #576DA4;
}


//文本-缩进

//box
//box-bg
.box-bg-def{
  background-color: $base-color * 5;
}

//边框圆角git
.radius-half{
  border-radius: 50%;
}
.radius-xs-tr-bl{
  border-radius: 0 6px 0 6px;
}

//layout-position
@mixin layout-position($position){
  position: $position;
}
.relative{
  @include layout-position($relative);
}
.absolute{
  @include layout-position($absolute);
  top:0;
  left: 0;
}
.fixed{
  @include layout-position($fixed);
}

//Layout-flex
@mixin flex-box($direction,$mainAxis,$crossAxis){
  display: $layout-flex;
  flex-direction: $direction;
  justify-content: $mainAxis;
  align-items: $crossAxis;
}
.box-row-start-start{
  @include flex-box($row,$align-start,$align-start);
}
.box-row-start-m{
  @include flex-box($row,$align-start,$align-center);
}
.box-row-start-base{
  @include flex-box($row,$align-start,$baseline);
}
.box-row-m-m{
  @include flex-box($row,$align-center,$align-center);
}
.box-row-m-start{
  @include flex-box($row,$align-center,$align-start);
}
.box-row-m-base{
  @include flex-box($row,$align-center,$baseline);
}
.box-row-end-m{
  @include flex-box($row,$align-end,$align-center);
}
.box-row-between-start{
  @include flex-box($row,$align-between,$align-start);
}
.box-row-between-m{
  @include flex-box($row,$align-between,$align-center);
}
.box-row-between-base{
  @include flex-box($row,$align-between,$baseline);
}
.box-row-around-m{
  @include flex-box($row,$align-around,$align-center);
}


.box-column-start-start{
  @include flex-box($column,$align-start,$align-start);
}
.box-column-start-m{
  @include flex-box($column,$align-start,$align-center);
}
.box-column-m-start{
  @include flex-box($column,$align-center,$align-start);
}
.box-column-m-m{
  @include flex-box($column,$align-center,$align-center);
}
.box-column-between-m{
  @include flex-box($column,$align-between,$align-center);
}
.box-column-m-between{
  @include flex-box($column,$align-center,$align-between);
}
.box-column-between-start{
  @include flex-box($column,$align-between,$align-start);
}
.box-column-around-m{
  @include flex-box($column,$align-around,$align-center);
}


.box-flex-def{
  flex: $flex;
}


//活动标签
.tag-success,.tag-def{
  width: 52px;
  height: 20px;
  margin-right: 6px;
  border-radius: 3px;
  @extend .box-row-m-m;
  white-space: nowrap;
  font-family: HarmonyOSSansSC-Regular;
}
.tag-success{
  background-color: #FFF1F1;
  @extend .text-danger;
}

.tag-def{
  background-color: #E6E6E6;
  @extend .text-def;
}

.btn-createActivity{
  width: 96px;
  height: 40px;
  background: linear-gradient(137.05deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%);
  border-radius: 8px;
  margin-top: 12px;
}

.border{
  border: 1px solid black;
}
.ZABtn{
  height: 48px;
  margin-top: 36px;
  background: linear-gradient(137.05deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%);
  box-shadow: 0px 4px 8px rgba(255, 37, 37, 0.25);
  color:#ffffff;
  font-family: HarmonyOSSansSC-Medium;

}
.grid{
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-row-gap: 4px;
  grid-column-gap: 4px;
  justify-content: space-between;
  align-content:center;
}
.bottomActionFixed{
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
}