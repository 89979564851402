.pdfViewerWrap {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.back-link {
    color: #1a0dab; /* Google蓝色链接颜色 */
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 10px;
}

.back-link:hover {
    text-decoration: underline;
}

.pdfContainer {
    flex: 1;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    overflow: auto;
}
