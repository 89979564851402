.card-code {
    position: relative;
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-top: 75px;
}

.card-code .status {
    position: absolute;
    right: -15px;
    top: -15px;
    width: 75px;
    height: 75px;
    z-index: 1;
}

.card-code .status img {
    width: 100%;
    height: 100%;
}

.card-code .head {
    position: relative;
    width: 100%;
    padding: 16px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    border-bottom: 1px dashed #E6E6E6;
}

.card-code .head::after,
.card-code .head::before {
    content: '';
    position: absolute;
    bottom: -4px;
    width: 8px;
    height: 8px;
    background: #f0f2f5;
    border-radius: 50px;
}

.card-code .head::after {
    left: -4px;
}

.card-code .head::before {
    right: -4px;
}

.card-code .head .tit {
    display: block;
    width: 100%;
    color: #FF1E1E;
    font-size: 16px;
    line-height: 22px;
}

.card-code .head .info {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 6px;
}

.card-code .head .info>span:first-child {
    color: #999999;
}

.card-code .head .info>span:last-child {
    color: #151515;
}

.card-code .code-info {
    width: 100%;
    padding-bottom: 16px;
    background: #ffffff;
    border-radius: 6px;
}

.card-code .coupon-desc{
    padding: 12px;
    background: #F6F6F6;
    color: #999999;
    border-radius: 6px;
    margin: 20px 12px 4px 12px;
}

.card-code .code-info .code-number {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #333333;
    font-size: 14px;
    padding: 25px 0 8px 0;
}

.card-code .code-info .code-img {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 190px;
}

.card-code .code-info .code-img img {
    height: 100%;
}

.card-code .code-info .code-img img {
    height: 100%;
}

.code-img-alt {
    color: #999999;
    width: 190px;
    height: 190px;
    text-align: center;
    line-height: 190px;
    border: 1px dashed #999999;
    border-radius: 6px;
}

.card-code .code-info .code-tip {
    width: 100%;
    color: #999999;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
}


.consumer-join-act{
    background-color: rgba(51, 51, 51, 1);
    width: 100%;
    height:100%;
    position:relative;

}

.consumer-join-act .coupon-giving{
    text-align:center;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(255, 68, 51, 1);
    vertical-align: top;

}

.consumer-join-act .coupon-tips-1{

    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 14px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
    white-space:normal;
    line-height:30px;

}


.consumer-join-act .coupon-tips-2{

    opacity: 0.7;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 14px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
    margin-top:15px;

}


.consumer-join-act .goto-share {
    text-align:center;
    margin-left: auto;
    margin-right: auto;

}


.consumer-join-act .goto-share button{

    width: 327px;
    height: 44px;
    opacity: 1;
    border-radius: 6px;
    background: linear-gradient(180deg, rgba(255, 106, 77, 1) 0%, rgba(255, 68, 51, 1) 100%);
    box-shadow: 0px 4px 8px 0px rgba(255, 68, 51, 0.25);
}


.consumer-join-act .goto-share .goto-share-tip{

    opacity: 0.7;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 12px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;

}