.loginWrap h1 {
    color: #000;
    font-size: 24px;
    margin-bottom: 20px;
}

.loginWrap ul {
    list-style: none;
    padding: 0;
}

.loginWrap ul li {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.help-link {
    color: #1a0dab; /* Google蓝色链接颜色 */
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    flex-grow: 1;
}

.help-link:hover {
    text-decoration: underline;
}

.download-link {
    color: #1a0dab; /* Google蓝色链接颜色 */
    font-size: 16px;
    text-decoration: none;
    margin-left: 10px;
}

.download-link:hover {
    text-decoration: underline;
}
