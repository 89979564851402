.ant-modal-confirm{
    .ant-modal-content{
        border-radius: 10px;
    }
    
    .ant-modal-body{
        text-align: center;
    }
    
    .ant-modal-confirm-btns{
        float: none;
        text-align: center;
        margin-top:0px;
    }
    
    button.ant-btn{
        background-image:none;
        box-shadow: none;
        background: none;
        border:none;
        text-shadow: none;
        font-size: 16px;
    }
    
    button.ant-btn{
        color: #cccccc;
        width: 50%;
        margin: 0px !important;
        height: 46px;
    }
    
    button.ant-btn-primary{
        color:#5786eb !important;
        width: 100%;
    }
    
    
    button.ant-btn + .ant-btn-primary{
        width: 50%;
    }
    
    .ant-modal-confirm-body .anticon{
        display: none;
    }
    
    .ant-modal-confirm-content{
        padding: 20px;
        font-size: 16px;
        /* color: #A2A2A2 !important; */
    }
    .ant-modal-confirm-content .light{
        margin-top:10px;
        font-size: 14px;
    }
    
    .ant-modal-confirm-content p{
        margin:0px;
    }
    
    .ant-modal-confirm-btns{
        border-top: 1px solid #efefec;
    }
    
    .ant-modal-body{
        padding: 0px;
    }
    
    
    .ant-form-item .ant-form-item-label{
        padding-bottom: 0px;
    }
    
    .ant-form-item-explain-error{
        visibility: hidden;
    }
}

.ant-modal-confirm, .bkm-modal{
    max-width: calc(100vw - 100px);
    top: 30vh;
}

.bkm-modal{
    .ant-modal-content{
        border-radius: 10px;
    }

    .ant-btn{
        border:none
    }
} 

.ant-radio-checked{
    width: 16px;
    height: 16px;
    padding: 8px;
    background: url('../assets/icon/radio_selected.png') no-repeat;
    background-size: 100% auto;
}
  
.btn{
    background:none;
}
  
.ant-radio-checked > *{
    display: none;
}


.ant-radio-button-wrapper h3{
    color: inherit;
    font-size: 20px;
}
  
.ant-radio-button-wrapper:not(:first-child)::before{
    background-color: #2D49FE;
}

.ant-radio-group-solid > label:first-child{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.ant-radio-group-solid > label:last-child{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

button.ant-btn-lg{
    padding: 0px 21px;
    height: 44px;
    font-size: 16px;  
    border-radius: 6px;
}

button.ant-btn-lg:not(:active){
    color: #999;
}

button.ant-btn-default{
    border-color: #2D49FE;
}

button.ant-btn-primary{
    background-image: linear-gradient(180deg, #5097FF 0%, #2542FF 100%);
    box-shadow: 0 4px 8px 0 rgba(45,73,254,0.30);
    color: white !important;
}

.ant-btn-lg.ant-btn-primary{
    padding: 0px 56px;
}

button.ant-btn-primary:disabled{
    background-color: rgba(255, 186, 185, 1);
}

.ant-form-item-explain-error{
    visibility: hidden;
}

.prefixInput{
    height: 40px;
    border: none;
}
.am-steps-item-process .step-index{
    background: linear-gradient(180deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%);
}
.steps .current .step-title, .am-steps-item-process .am-steps-item-title {
    background: rgba(255, 37, 37, 1) !important;
}
.ant-modal-mask{
    transform: translateZ(1000px);
}
.ant-modal-wrap{
    transform: translateZ(1000px);
}