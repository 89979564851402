
.my-activities {

  .result-icon{
    width: 149px;
    height: 114px;
  }

  .placeholderWrap{
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
  .companyLogoWrap{
    height: 44px;
    position: absolute;
    left: 154px;
    bottom:0;

    .companyLogo{
      width: 68px;
      height: 25px;
    }
  }

}
.activity-item-wrap{
  padding: 4px 0px;
}
.activity-status-icon{
  width: 48px;
  height: 48px;
}
.actTipsActive,.actTips{
  width:182px;
  align-self:flex-end;
}

.actTipsActive{
  color:#FFCA8B;
}
.actTips{
  color:#576DA4;
}
.activityDataLogo{
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.activityDataBox,.activityDataBoxActive{
  height: 216px;
  margin-top: 16px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.05);
  padding: 12px;


  .block-stat{
    margin-top: 6px;
    padding: 10px 18px;
    //background-color: white;
    font-size: 12px;

    h3{
      font-size: 24px;
    }
  }

  .block-stat label{
    color: #999999;
  }

}
.activityDataBox{
  background: url("../../../assets/img/activityDataBg.jpg") no-repeat;
  background-size: 100% 100%;
}

.activityDataBoxActive{
  background: url("../../../assets/img/seller-header-bg.jpg") no-repeat;
  background-size: 100% 100%;
}

.qr-code{
  width: 150px;
  height: 150px;
}
.qr-code img{
  width: 145px;
  height: 145px;
}
.posterBox{

  .am-modal{
    overflow: hidden;
  }
  .am-modal-transparent .am-modal-content {
    background-color: rgba(255,255,255,0) !important;
    padding-top: 0!important;
  }
  .am-modal-transparent .am-modal-content .am-modal-body{
    padding: 0!important;
  }

  .am-button .am-button-ghost{
    border:1px solid #fff!important;
  }
}
.activity-title-wrap{
  height: 33px;
}